import { PhoneNumberUtil } from "google-libphonenumber"
const phoneUtil = PhoneNumberUtil.getInstance()

const validators = {
  phone: (value) => {
    try {
      const result = phoneUtil.parse(value)
      return phoneUtil.isValidNumber(result)
    } catch {
      return false
    }
  },
}

export default validators
